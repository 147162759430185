import makeValidationSchema from './InvoiceSummaryMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useState } from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import {
  Box,
  Card,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import ShipmentSummarySection from './ShipmentSummarySection'
import SummaryReferenceDialog from './SummaryReferenceDialog'

const {
  button: { CngIconButton },
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  totalInvoice: '',
  nbciUSInvoiceShipmentSummary: [],
  nbciusinvoiceSummaryReference: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 8px 8px 16px',
    '& .MuiIconButton-root': {
      minHeight: 'auto'
    }
  },
  badge: {
    padding: 4,
    minWidth: 40,
    borderRadius: 32
  }
}))

function Fields({ disabled, shouldHideMap }) {
  const [referencesDialog, setReferencesDialog] = useState(false)
  const { setValue, watch } = useFormContext()
  const references = watch('nbciusinvoiceSummaryReference')

  const classes = useStyles()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const totalInvoice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceSummary.TOTAL_INVOICE
    )
    const summaryReference = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceSummaryReference.TITLE
    )

    return {
      totalInvoice,
      summaryReference
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ShipmentSummarySection />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.totalInvoice}>
              <CngTextField
                name='totalInvoice'
                inputProps={{ maxLength: 16 }}
                label={translatedTextsObject.totalInvoice}
                disabled={disabled}
                isRequired
                size='small'
              />
            </CngGridItem>
            <Grid item xs={12} sm={6}>
              <Card variant='outlined'>
                <Box className={classes.wrapper}>
                  <Box flexGrow='1'>
                    <Typography variant='body2' style={{ fontWeight: 600 }}>
                      {translatedTextsObject.summaryReference}
                    </Typography>
                  </Box>
                  <div>
                    <Paper className={classes.badge} variant='outlined'>
                      <Typography align='center' variant='body2' style={{ fontWeight: 600 }}>
                        {references.length}
                      </Typography>
                    </Paper>
                  </div>
                  <CngIconButton
                    icon={['fal', 'pen']}
                    onClick={() => setReferencesDialog(true)}
                    size='small'
                    type='outlined'
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SummaryReferenceDialog
        onSave={(summaryReference) => {
          setValue('nbciusinvoiceSummaryReference', summaryReference, { shouldDirty: true })
          setReferencesDialog(false)
        }}
        onClose={() => setReferencesDialog(false)}
        open={referencesDialog}
        references={references}
      />
    </>
  )
}

const InvoiceSummaryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default InvoiceSummaryFormProperties
