import React, { useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import NbciUsInvoiceApiUrls from '../../../../apiUrls/NbciUsInvoiceApiUrls'
import CngSection from '../../../../components/cngcomponents/CngSection'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import AdditionalFormItem from '../../components/AdditionalFormItem'
import LaceyActFormViewContent from './LaceyActFormViewContent'
import PackingListFormViewContent from './PackingListFormViewContent'
import TscaFormViewContent from './TscaFormViewContent'
import BolFormViewContent from './BolFormViewContent'
import FdabtaFormViewContent from './FdabtaFormViewContent'
import USAPHISFormViewContent from './USAPHISFormViewContent'
import NaftaFormViewContent from './NaftaFormViewContent'
import _ from 'lodash'

const {
  button: { CngButton },
  CngDialog
} = components

function AdditionalFormInfoSection(props) {
  const { invoiceData, showNotification } = props

  const [viewDialog, setViewDialog] = useState({
    open: false,
    formKey: '',
    form: []
  })
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const laceyAct = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.TITLE
    )
    const packingList = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.TITLE
    )
    const tscaform = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.TITLE
    )
    const bolForm = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.TITLE
    )
    const fdabtaform = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.TITLE
    )
    const usAPHISForm = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.TITLE
    )
    const naftaForm = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.TITLE
    )

    return {
      laceyAct,
      packingList,
      tscaform,
      bolForm,
      fdabtaform,
      usAPHISForm,
      naftaForm
    }
  }

  function handleDownload({ url, prefix }) {
    const config = { responseType: 'blob' } 
    securedSendRequest.execute(
      'POST',
      url,
      invoiceData,
      (response) => {
        const blob = new Blob([response.data])
        const curDate = format(new Date(), 'yyyyMMddHHmmss')
        const a = document.createElement('a')

        a.href = window.URL.createObjectURL(blob)
        a.download = `${prefix}_${curDate}.pdf`
        a.click()
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Download failed')
      },
      undefined,
      config,
      null
    )
  }

  function renderDialogContent(form) {
    switch (viewDialog.formKey) {
      case 'laceyAct':
        return <LaceyActFormViewContent laceyAct={form} />
      case 'packingList':
        return <PackingListFormViewContent packingList={form} />
      case 'tscaform':
        return <TscaFormViewContent tscaform={form} />
      case 'bolForm':
        return <BolFormViewContent bolForm={form} />
      case 'fdabtaform':
        return <FdabtaFormViewContent fdabtaform={form} />
      case 'usAPHISForm':
        return <USAPHISFormViewContent usAPHISForm={form} />
      case 'naftaForm':
        return <NaftaFormViewContent naftaForm={form} />

      default:
        return (
          <Typography variant='body2' color='textSecondary'>
            No records to display
          </Typography>
        )
    }
  }

  return (
    <>
      <CngSection title='Additional Form Information'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.laceyAct}
              data={invoiceData.laceyAct}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.laceyAct)) return

                setViewDialog({
                  open: true,
                  formKey: 'laceyAct',
                  form: invoiceData.laceyAct
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NbciUsInvoiceApiUrls.PRINT_LACEYACT,
                  prefix: 'NBCI_LACEYACT'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.packingList}
              data={invoiceData.packingList}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.packingList)) return

                setViewDialog({
                  open: true,
                  formKey: 'packingList',
                  form: invoiceData.packingList
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NbciUsInvoiceApiUrls.PRINT_PL,
                  prefix: 'NBCI_PL'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.tscaform}
              data={invoiceData.tscaform}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.tscaform)) return

                setViewDialog({
                  open: true,
                  formKey: 'tscaform',
                  form: invoiceData.tscaform
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NbciUsInvoiceApiUrls.PRINT_TSCA,
                  prefix: 'NBCI_TSCA'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.bolForm}
              data={invoiceData.bolForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.bolForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'bolForm',
                  form: invoiceData.bolForm
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NbciUsInvoiceApiUrls.PRINT_BOL,
                  prefix: 'NBCI_BOL'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.fdabtaform}
              data={invoiceData.fdabtaform}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.fdabtaform)) return

                setViewDialog({
                  open: true,
                  formKey: 'fdabtaform',
                  form: invoiceData.fdabtaform
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NbciUsInvoiceApiUrls.PRINT_FDA,
                  prefix: 'NBCI_FDA'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.usAPHISForm}
              data={invoiceData.usAPHISForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.usAPHISForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'usAPHISForm',
                  form: invoiceData.usAPHISForm
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NbciUsInvoiceApiUrls.PRINT_USAPHIS,
                  prefix: 'NBCI_USAPHIS'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.naftaForm}
              data={invoiceData.naftaForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.naftaForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'naftaForm',
                  form: invoiceData.naftaForm
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NbciUsInvoiceApiUrls.PRINT_USMCACUSMA,
                  prefix: 'NBCI_USMCACUSMA'
                })
              }
            />
          </Grid>
        </Grid>
      </CngSection>
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <Grid container spacing={2}>
              {viewDialog.form.map((form) => (
                <Grid key={form.id} item xs={12}>
                  <Card variant='outlined'>
                    <Box padding={2}>{renderDialogContent(form)}</Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setViewDialog({ open: false, formKey: '', form: [] })}>
            OK
          </CngButton>
        }
        dialogTitle={translatedTextsObject?.[viewDialog.formKey] || ''}
        maxWidth='md'
        onClose={() => setViewDialog({ open: false, formKey: '', form: [] })}
        open={viewDialog.open}
        shouldShowCloseButton
      />
    </>
  )
}

export default AdditionalFormInfoSection
