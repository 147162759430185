import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Please enter alpha numeric values other than <,>,*,~'

  return Yup.object({
    referenceType: Yup.string().nullable(),
    referenceIdentification: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    refDescription: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
