import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexDigitWithDecimal = '^[0-9]{0,4}(.[0-9]{0,4})?$'
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 4 digits and  3 decimal'
  const regexDigitWith2Decimal = '^[0-9]{0,9}(.[0-9]{0,2})?$'
  const errMsgDigitWith2Decimal =
    'Please enter only numeric values with up to 9 digits and  2 decimal'
  const regex6DigitWith2Decimal = '^[0-9]{0,6}(.[0-9]{0,2})?$'
  const errMsg6DigitWith2Decimal =
    'Please enter only numeric values with up to 6 digits and  2 decimal'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    containerNo: Yup.string()
      .nullable()
      .max(30, 'Max values allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    freightCharges: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    placeOfLadding: Yup.string()
      .nullable()
      .max(30, 'Max values allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    deliveryInstrctions: Yup.string()
      .nullable()
      .max(50, 'Max values allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    codAmount: Yup.string()
      .nullable()
      .max(8, 'Max values allowed is 8')
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal),
    bolCurrency: Yup.string().nullable(),
    declaredValueOfShipment: Yup.string()
      .nullable()
      .max(12, 'Max values allowed is 12')
      .matches(regexDigitWith2Decimal, errMsgDigitWith2Decimal),
    chargeTo: Yup.string()
      .nullable()
      .max(50, 'Max values allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    releaseValue: Yup.string()
      .nullable()
      .max(9, 'Max values allowed is 9')
      .matches(regex6DigitWith2Decimal, errMsg6DigitWith2Decimal)
  })
}

export default makeValidationSchema
