import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    portCode: Yup.string().nullable(),
    entityDate: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    entryNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    engineBuildDate: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    onEngine: Yup.string().nullable(),
    other: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    engineManufacturer: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    vehicleManufacturer: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    printedName: Yup.string()
      .nullable()
      .max(100, 'Max length allowed is 100')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    printedDate: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    equipmentTypeA: Yup.boolean().nullable(),
    equipmentTypeB: Yup.boolean().nullable(),
    equipmentTypeC: Yup.boolean().nullable(),
    equipmentTypeD: Yup.boolean().nullable(),
    equipmentTypeE: Yup.boolean().nullable(),
    equipmentTypeF: Yup.boolean().nullable(),
    equipmentTypeG: Yup.boolean().nullable(),
    equipmentTypeH: Yup.boolean().nullable(),
    equipmentType1: Yup.boolean().nullable(),
    equipmentType2: Yup.boolean().nullable(),
    equipmentType3: Yup.boolean().nullable(),
    equipmentType4: Yup.boolean().nullable(),
    equipmentType5: Yup.boolean().nullable(),
    equipmentType6: Yup.boolean().nullable(),
    equipmentType7: Yup.boolean().nullable(),
    equipmentType8: Yup.boolean().nullable(),
    equipmentType9: Yup.boolean().nullable(),
    equipmentType10: Yup.boolean().nullable(),
    equipmentType11: Yup.boolean().nullable(),
    equipmentType12: Yup.boolean().nullable(),
    equipmentType13: Yup.boolean().nullable(),
    equipmentType14: Yup.boolean().nullable(),
    equipmentType15: Yup.boolean().nullable(),
    equipmentType16: Yup.boolean().nullable(),
    equipmentType17: Yup.boolean().nullable(),
    equipmentType18: Yup.boolean().nullable(),
    equipmentType19: Yup.boolean().nullable(),
    equipmentType20: Yup.boolean().nullable(),
    equipmentType21: Yup.boolean().nullable(),
    equipmentType22: Yup.boolean().nullable(),
    equipmentType23: Yup.boolean().nullable(),
    equipmentType24: Yup.boolean().nullable(),
    equipmentType25: Yup.boolean().nullable(),
    equipmentType26A: Yup.boolean().nullable(),
    equipmentType26B: Yup.boolean().nullable(),
    equipmentType26C: Yup.boolean().nullable(),
    equipmentType24Power: Yup.boolean().nullable(),
    equipmentType24Watts: Yup.boolean().nullable()
  })
}

export default makeValidationSchema
