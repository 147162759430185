import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../../constants/locale/Namespace'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    containerNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    printName: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    descOfMerchandise: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    laceyDate: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    estimatedArrivalDate: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
  })
}

export default makeValidationSchema
