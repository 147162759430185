import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexDigitWithoutDecimal = '^[0-9]{0,6}$'
  const errMsgDigitWithoutDecimal =
    'Please enter only numeric values with up to 6 digits'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    deviceModel: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    tradeName: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    fccId: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    equipDesc: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    dateOfEntry: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    entryNO: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    detailsPortOfEntry: Yup.string().nullable(),
    htsNo: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    quantityOfItem: Yup.string()
      .nullable()
      .max(6, 'Max length allowed is 6')
      .matches(regexDigitWithoutDecimal, errMsgDigitWithoutDecimal)
  })
}

export default makeValidationSchema
