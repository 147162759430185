import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import InvoiceDetailsViewContent from './view/InvoiceDetailsViewContent'
import USInvoiceLookupsProvider from '../../lookups/USInvoiceLookupsContext'
import AlertDialog from '../../../../components/aciacehighway/AlertDialog'
import DialogForm from '../../../../components/aciacehighway/DialogForm'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import InvoiceDetailsFormProperties from './InvoiceDetailsFormProperties'
import { Box, Card, Typography, useTheme } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PrintFlagDialog from '../../../../views/nbci/usinvoice/PrintFlagLineIteamDialog'


const {
  button: { CngButton },
  CngDialog
} = components

function InvoiceDetailsTable() {
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    invoiceDetails: []
  })
  const [formDialog, setFormDialog] = useState({
    open: false,
    invoiceDetail: null
  })
  const [viewDialog, setViewDialog] = useState({
    open: false,
    invoiceDetail: null
  })

  const [printFlagDialog, setPrintFlagDialog] = useState({
    open: false,
    invoiceDetails: null
  })

  const { getValues, setValue, watch } = useFormContext()
  const invoiceDetails = watch('invoiceDetails')
  const theme = useTheme()

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceDetails = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.TITLE
    )
    const partNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PART_NUMBER
    )
    const productDescription = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PRODUCT_DESCRIPTION
    )
    const htsCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.HTS_CODE
    )
    const quantityInvoiced = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.QUANTITY_INVOICED
    )
    const unitPrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.UNIT_PRICE
    )
    const netWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_WEIGHT
    )
    const lineItemTotal = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.LINE_ITEM_TOTAL
    )

    return {
      invoiceDetails,
      partNumber,
      productDescription,
      htsCode,
      quantityInvoiced,
      unitPrice,
      netWeight,
      lineItemTotal
    }
  }

  const columns = [
    {
      field: 'partNumber',
      sortKey: 'partNumber',
      title: translatedTextsObject.partNumber
    },
    {
      field: 'productDescription',
      sortKey: 'productDescription',
      title: translatedTextsObject.productDescription
    },
    {
      field: 'htsCode',
      sortKey: 'htsCode',
      title: translatedTextsObject.htsCode
    },
    {
      field: 'quantityInvoiced',
      sortKey: 'quantityInvoiced',
      title: translatedTextsObject.quantityInvoiced
    },
    {
      field: 'unitPrice',
      sortKey: 'unitPrice',
      title: translatedTextsObject.unitPrice
    },
    {
      field: 'netWeight',
      sortKey: 'netWeight',
      title: translatedTextsObject.netWeight
    },
    {
      field: 'lineItemTotal',
      sortKey: 'lineItemTotal',
      title: translatedTextsObject.lineItemTotal
    }
  ]

  function handleAddInvoiceDetail(data) {
    const invoiceDetails = [...getValues('invoiceDetails'), data]

    setValue('invoiceDetails', invoiceDetails)
    setFormDialog({ open: false, invoiceDetail: null })
  }

  function getClonedInvoiceDetail(invoiceDetail) {
    let result = { ...invoiceDetail, id: undefined }

    const fields = [
      'nbciusinvoiceDetailsService',
      'nbciusinvoiceDetailsReference',
      'nbciusinvoiceDetailsDOTForm',
      'nbciusinvoiceDetailsFDAForm',
      'nbciusinvoiceDetailsFCCForm',
      'nbciusinvoiceDetailsEPA35201Form',
      'nbciusinvoiceDetailsEPA352021Form'
    ]

    fields.forEach((field) => {
      result[field].forEach((entry) => {
        entry['id'] = undefined
      })
    })

    return result
  }

  function handleDeleteInvoiceDetail() {
    if (_.isEmpty(confirmDialog.invoiceDetails)) return

    const _ids = confirmDialog.invoiceDetails.map((invoice) => invoice._id)
    const invoiceDetails = [...getValues('invoiceDetails')].filter((invoice) =>
      !_ids.includes(invoice._id)
    )

    setValue('invoiceDetails', invoiceDetails)
    setConfirmDialog({ open: false, invoiceDetails: [] })
  }

  function handleEditInvoiceDetail(data) {
    const invoiceDetails = [...getValues('invoiceDetails')]
    const index = invoiceDetails.findIndex((invoice) => invoice._id === data._id)

    invoiceDetails[index] = data

    setValue('invoiceDetails', invoiceDetails)
    setFormDialog({ open: false, invoiceDetail: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          header={
            <Typography style={{ fontWeight: 600 }}>
              {translatedTextsObject.invoiceDetails}
            </Typography>
          }
          columns={columns}
          data={invoiceDetails}
          actions={[
            {
              icon: ['fal', 'plus-circle'],
              label: 'Create new line item',
              onClick: () => setFormDialog({ open: true, invoiceDetail: null })
            }
          ]}
          moreActions={[
            {
              icon: <FontAwesomeIcon fixedWidth icon={['fal', 'copy']} />,
              label: 'Clone',
              onClick: (rowData) =>
                handleAddInvoiceDetail(getClonedInvoiceDetail(rowData))
            },
            {
              icon: <FontAwesomeIcon fixedWidth icon={['fal', 'trash']} />,
              label: 'Delete',
              onClick: (rowData) =>
                setConfirmDialog({ open: true, invoiceDetails: [rowData] })
            },
            {
              icon: <FontAwesomeIcon fixedWidth icon={['fal', 'print']} />,
              label: 'Print Forms',
              onClick: (rowData) =>
              setPrintFlagDialog({ open: true, invoiceDetails: rowData })
            }
          ]}
          onRowClick={(rowData) => setViewDialog({ open: true, invoiceDetail: rowData })}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) =>
                setFormDialog({ open: true, invoiceDetail: rowData }),
              tooltip: 'Edit'
            }
          ]}
          select
          selectActions={[
            {
              icon: ['fal', 'trash'],
              tooltip: `Delete`,
              label: 'Delete',
              onClick: (selectedRows) =>
                setConfirmDialog({ open: true, invoiceDetails: selectedRows }),
              buttonProps: {
                style: {
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main
                }
              }
            }
          ]}
        />
      </Card>
      <DialogForm
        disableBackdropClick
        formProperties={InvoiceDetailsFormProperties}
        fullWidth
        maxWidth='lg'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, invoiceDetail: null })}
        onSubmit={
          formDialog.invoiceDetail
            ? handleEditInvoiceDetail
            : handleAddInvoiceDetail
        }
        title={`${formDialog.invoiceDetail ? 'Edit' : 'Add new'} line item`}
        value={formDialog.invoiceDetail}
      />
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <USInvoiceLookupsProvider>
              <InvoiceDetailsViewContent invoiceDetail={viewDialog.invoiceDetail} />
            </USInvoiceLookupsProvider>
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setViewDialog({ open: false, invoiceDetail: null })}>
            OK
          </CngButton>
        }
        dialogTitle='View line item'
        maxWidth='md'
        onClose={() => setViewDialog({ open: false, invoiceDetail: null })}
        open={viewDialog.open}
        shouldShowCloseButton
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, invoiceDetails: [] })}
        onCancel={() => setConfirmDialog({ open: false, invoiceDetails: [] })}
        onConfirm={handleDeleteInvoiceDetail}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
      <PrintFlagDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, invoiceDetails: null })}
        //showNotification={showNotification}
      />
    </>
  )
}

export default InvoiceDetailsTable
