import makeValidationSchema from './LaceyActDetailsMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import { Grid } from '@material-ui/core'

const {
  form: {
    field: { CngTextField, CngCountryAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  htsNo: '',
  enteredValue: '',
  componentOfArticle: '',
  genusGame: '',
  speciesName: '',
  country: '',
  quantity: '',
  uom: '',
  percentOfRecycle: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const htsNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.HTS_NO
    )
    const enteredValue = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.ENTERED_VALUE
    )
    const componentOfArticle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.COMPONENT_OF_ARTICLE
    )
    const genusGame = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.GENUS_GAME
    )
    const speciesName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.SPECIES_NAME
    )
    const country = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.COUNTRY
    )
    const quantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.QUANTITY
    )
    const uom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.UOM
    )
    const percentOfRecycle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.PERCENT_OF_RECYCLE
    )

    return {
      htsNo,
      enteredValue,
      componentOfArticle,
      genusGame,
      speciesName,
      country,
      quantity,
      uom,
      percentOfRecycle
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.htsNo}>
        <CngTextField
          name='htsNo'
          inputProps={{ maxLength: 10 }}
          label={translatedTextsObject.htsNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.enteredValue}>
        <CngTextField
          name='enteredValue'
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.enteredValue}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.genusGame}>
        <CngTextField
          name='genusGame'
          inputProps={{ maxLength: 40 }}
          label={translatedTextsObject.genusGame}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.speciesName}>
        <CngTextField
          name='speciesName'
          inputProps={{ maxLength: 40 }}
          label={translatedTextsObject.speciesName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.country}>
        <CngCountryAutocompleteField
          name='country'
          label={translatedTextsObject.country}
          disabled={disabled}
          size='small'
		  lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name='quantity'
          inputProps={{ maxLength: 12 }}
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.uom}>
        <CngTextField
          name='uom'
          inputProps={{ maxLength: 10 }}
          label={translatedTextsObject.uom}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.percentOfRecycle}>
        <CngTextField
          name='percentOfRecycle'
          inputProps={{ maxLength: 12 }}
          label={translatedTextsObject.percentOfRecycle}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.componentOfArticle}>
        <CngTextField
          name='componentOfArticle'
          inputProps={{ maxLength: 40 }}
          label={translatedTextsObject.componentOfArticle}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const NBCIUSInvoiceLaceyActDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NBCIUSInvoiceLaceyActDetailsFormProperties
