import makeValidationSchema from './LaceyActMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import LaceyActDetailsSection from './LaceyActDetailsSection'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNo: '',
  printName: '',
  descOfMerchandise: '',
  laceyDate: '',
  estimatedArrivalDate: '',
  nbciusinvoiceLaceyActDetails: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const containerNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.CONTAINER_NO
    )
    const printName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.PRINT_NAME
    )
    const descOfMerchandise = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.DESC_OF_MERCHANDISE
    )
    const laceyDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.LACEY_DATE
    )
    const estimatedArrivalDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.ESTIMATED_ARRIVAL_DATE
    )

    return {
      containerNo,
      printName,
      descOfMerchandise,
      laceyDate,
      estimatedArrivalDate
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.containerNo}>
            <CngTextField
              name='containerNo'
              inputProps={{ maxLength: 30 }}
              label={translatedTextsObject.containerNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.printName}>
            <CngTextField
              name='printName'
              inputProps={{ maxLength: 30 }}
              label={translatedTextsObject.printName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.laceyDate}>
            <CngDateField
              name='laceyDate'
              label={translatedTextsObject.laceyDate}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.estimatedArrivalDate}>
            <CngDateField
              name='estimatedArrivalDate'
              label={translatedTextsObject.estimatedArrivalDate}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.descOfMerchandise}>
            <CngTextField
              name='descOfMerchandise'
              inputProps={{ maxLength: 30 }}
              label={translatedTextsObject.descOfMerchandise}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LaceyActDetailsSection />
      </Grid>
    </Grid>
  )
}

const LaceyActFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default LaceyActFormProperties
