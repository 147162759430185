import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../../constants/locale/Namespace'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const faxParrter = '^[0-9\\-\\+\\(\\)\\ ]+'
  const errFaxPattern = 'Please enter valid Fax'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'
  const phonePattern = '^[0-9 -]*$'
  const phoneErrorMessage =
    'Please enter valid phone number[digits with space or -]'

  return Yup.object({
    blanketPeriodFrom: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    blanketPeriodTo: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    company: Yup.string()
      .nullable()
      .max(100, 'Max value allowed is 100')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    title: Yup.string()
      .nullable()
      .max(50, 'Max value allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    naftaDate: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    naftapartyName: Yup.string()
      .nullable()
      .max(60, 'Max value allowed is 60')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    contactTelNum: Yup.string()
      .nullable()
      .matches(phonePattern, phoneErrorMessage)
      .max(30, 'Max length allowed is 30'),
    contactFax: Yup.string()
      .nullable()
      .matches(faxParrter, errFaxPattern)
      .max(35, 'Max length allowed is 35'),
    email: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .email('Invalid email')
      .matches('[A-Za-z0-9+_.-]+@(.+)$', 'Enter Email in valid pattern'),
    cerifierType: Yup.string().nullable()
  })
}

export default makeValidationSchema
