import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexDigitWithoutrDecimal = '^[0-9]{0,6}$'
  const errMsgDigitWithoutDecimal =
    'Please enter only numeric values with up to 6 digits without decimal'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    productDesc: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    quantity: Yup.string()
      .nullable()
      .max(6, 'Max length allowed is 06')
      .matches(regexDigitWithoutrDecimal, errMsgDigitWithoutDecimal),
    modelNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    brandName: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
