import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import makeValidationSchema from './DOTFormMakeValidationSchema'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import NaUSForeignPortAutocompleteField from '../../../../components/na/autocomplete/codemaintenance/NaUSForeignPortAutocompleteField'
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { NaAceHwyPOEPortAutocompleteField } from 'src/components/na'

const {
  form: {
    field: { CngTextField, CngDateField, CngCheckboxField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  portCode: '',
  behicleMake: '',
  vehicleEligibilityNo: '',
  eqipDesc: '',
  customsEntryNo: '',
  model: '',
  entryDate: '',
  year: '',
  vehicleIdentificationNo: '',
  registerImporterName: '',
  nhtsaregNo: '',
  importerName: '',
  importerAddress: '',
  declarantName: '',
  declarantAddress: '',
  dateSigned: '',
  safety1: false,
  safety2A: false,
  safety2B: false,
  safety3: false,
  safety4: false,
  safety5: false,
  safety6: false,
  safety7: false,
  safety8: false,
  safety9: false,
  safety10: false,
  safety11: false,
  safety12: false,
  safety13: false
})

const safetyFields = [
  'safety1',
  'safety2A',
  'safety2B',
  'safety3',
  'safety4',
  'safety5',
  'safety6',
  'safety7',
  'safety8',
  'safety9',
  'safety10',
  'safety11',
  'safety12',
  'safety13'
]

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { getValues, setValue } = useFormContext()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const portCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.PORT_CODE
    )
    const behicleMake = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.BEHICLE_MAKE
    )
    const vehicleEligibilityNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.VEHICLE_ELIGIBILITY_NO
    )
    const eqipDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.EQIP_DESC
    )
    const customsEntryNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.CUSTOMS_ENTRY_NO
    )
    const model = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.MODEL
    )
    const entryDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.ENTRY_DATE
    )
    const year = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.YEAR
    )
    const vehicleIdentificationNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.VEHICLE_IDENTIFICATION_NO
    )
    const registerImporterName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.REGISTER_IMPORTER_NAME
    )
    const nhtsaregNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.N_H_T_S_A_REG_NO
    )
    const importerName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.IMPORTER_NAME
    )
    const importerAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.IMPORTER_ADDRESS
    )
    const declarantName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.DECLARANT_NAME
    )
    const declarantAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.DECLARANT_ADDRESS
    )
    const dateSigned = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.DATE_SIGNED
    )
    const safety1 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY1
    )
    const safety2A = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY2_A
    )
    const safety2B = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY2_B
    )
    const safety3 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY3
    )
    const safety4 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY4
    )
    const safety5 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY5
    )
    const safety6 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY6
    )
    const safety7 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY7
    )
    const safety8 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY8
    )
    const safety9 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY9
    )
    const safety10 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY10
    )
    const safety11 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY11
    )
    const safety12 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY12
    )
    const safety13 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY13
    )

    return {
      portCode,
      behicleMake,
      vehicleEligibilityNo,
      eqipDesc,
      customsEntryNo,
      model,
      entryDate,
      year,
      vehicleIdentificationNo,
      registerImporterName,
      nhtsaregNo,
      importerName,
      importerAddress,
      declarantName,
      declarantAddress,
      dateSigned,
      safety1,
      safety2A,
      safety2B,
      safety3,
      safety4,
      safety5,
      safety6,
      safety7,
      safety8,
      safety9,
      safety10,
      safety11,
      safety12,
      safety13
    }
  }

  function getFieldName(field) {
    return typeof index === 'number'
      ? `nbciusinvoiceDetailsDOTForm.${index}.${field}`
      : field
  }

  function handleCheckAll(value) {
    safetyFields.forEach((field) => setValue(getFieldName(field), value))
  }

  const checkedSafetyFields = safetyFields.filter((field) =>
    getValues(getFieldName(field))
  )

  const isAllChecked = checkedSafetyFields.length === safetyFields.length

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.portCode}>
            <NaAceHwyPOEPortAutocompleteField
              name={getFieldName('portCode')}
              label={translatedTextsObject.portCode}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.customsEntryNo}>
            <CngTextField
              name={getFieldName('customsEntryNo')}
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.customsEntryNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.entryDate}>
            <CngDateField
              name={getFieldName('entryDate')}
              label={translatedTextsObject.entryDate}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.behicleMake}>
            <CngTextField
              name={getFieldName('behicleMake')}
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.behicleMake}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.model}>
            <CngTextField
              name={getFieldName('model')}
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.model}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.year}>
            <CngTextField
              name={getFieldName('year')}
              inputProps={{ maxLength: 4 }}
              label={translatedTextsObject.year}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleIdentificationNo}>
            <CngTextField
              name={getFieldName('vehicleIdentificationNo')}
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.vehicleIdentificationNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleEligibilityNo}>
            <CngTextField
              name={getFieldName('vehicleEligibilityNo')}
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.vehicleEligibilityNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.registerImporterName}>
            <CngTextField
              name={getFieldName('registerImporterName')}
              inputProps={{ maxLength: 35 }}
              label={translatedTextsObject.registerImporterName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.nhtsaregNo}>
            <CngTextField
              name={getFieldName('nhtsaregNo')}
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.nhtsaregNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.eqipDesc}>
            <CngTextField
              name={getFieldName('eqipDesc')}
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.eqipDesc}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Any person knowingly making a false declaration is subject to a
              fine of not more than $10,000 or imprisonment for not more than
              5 years or both (18 U.S.C. 1001).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleSection title='Federal motor vehicle safety details'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAllChecked}
                        indeterminate={!isAllChecked && checkedSafetyFields.length > 0}
                        onChange={(event) => handleCheckAll(event.target.checked)}
                      />
                    }
                    label='SELECT ALL'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography style={{ fontWeight: 600 }}>
                        Federal Motor Vehicle Safety, Bumper and Theft
                        Prevention Standards
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety1}>
                          <CngCheckboxField
                            name={getFieldName('safety1')}
                            label={translatedTextsObject.safety1}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety2A}>
                          <CngCheckboxField
                            name={getFieldName('safety2A')}
                            label={translatedTextsObject.safety2A}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety2B}>
                          <CngCheckboxField
                            name={getFieldName('safety2B')}
                            label={translatedTextsObject.safety2B}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety3}>
                          <CngCheckboxField
                            name={getFieldName('safety3')}
                            label={translatedTextsObject.safety3}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety4}>
                          <CngCheckboxField
                            name={getFieldName('safety4')}
                            label={translatedTextsObject.safety4}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety5}>
                          <CngCheckboxField
                            name={getFieldName('safety5')}
                            label={translatedTextsObject.safety5}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety6}>
                          <CngCheckboxField
                            name={getFieldName('safety6')}
                            label={translatedTextsObject.safety6}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety7}>
                          <CngCheckboxField
                            name={getFieldName('safety7')}
                            label={translatedTextsObject.safety7}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety8}>
                          <CngCheckboxField
                            name={getFieldName('safety8')}
                            label={translatedTextsObject.safety8}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety9}>
                          <CngCheckboxField
                            name={getFieldName('safety9')}
                            label={translatedTextsObject.safety9}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety10}>
                          <CngCheckboxField
                            name={getFieldName('safety10')}
                            label={translatedTextsObject.safety10}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety11}>
                          <CngCheckboxField
                            name={getFieldName('safety11')}
                            label={translatedTextsObject.safety11}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety12}>
                          <CngCheckboxField
                            name={getFieldName('safety12')}
                            label={translatedTextsObject.safety12}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.safety13}>
                          <CngCheckboxField
                            name={getFieldName('safety13')}
                            label={translatedTextsObject.safety13}
                            disabled={disabled}
                            formControlLabelProps={{
                              style: { alignItems: 'flex-start' }
                            }}
                          />
                        </CngGridItem>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CollapsibleSection>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.importerName}>
            <CngTextField
              name={getFieldName('importerName')}
              inputProps={{ maxLength: 35 }}
              label={translatedTextsObject.importerName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.importerAddress}>
            <CngTextField
              name={getFieldName('importerAddress')}
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.importerAddress}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.declarantName}>
            <CngTextField
              name={getFieldName('declarantName')}
              inputProps={{ maxLength: 35 }}
              label={translatedTextsObject.declarantName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.declarantAddress}>
            <CngTextField
              name={getFieldName('declarantAddress')}
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.declarantAddress}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dateSigned}>
            <CngDateField
              name={getFieldName('dateSigned')}
              label={translatedTextsObject.dateSigned}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>
  )
}

const DOTFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default DOTFormFormProperties
